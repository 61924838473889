<template>
  <div>
    <div class="team agileinfo">
      <div class="container co-text">
        <h3 class="agileits-title" style="margin-bottom: 1em;">
          《高考选科与智能专业定位系统》使用手册
        </h3>
        <h4 style="margin-bottom: 1em;">一、使用模式：系统有两种使用模式。</h4>
        <p>
          1.普通模式：该模式针对普通用户。进入系统主界面后按系统引导，先使用手机号进行注册，然后通过微信支付进行测评。
        </p>
        <p>
          2.特殊模式：该模式针对特殊用户。进入系统主界面后直接使用账号与密码登录进行测评，该模式无需进行用户注册，也无需付费。
        </p>
        <h4 style="margin-bottom: 1em;">
          二、使用特殊模式（账号和密码）测评步骤
        </h4>
        <p>1.使用手机测</p>
        <p>（1）通过微信扫描下方二维码。</p>
        <p style="text-align: center;">
          <img
            src="./../../../../icon/code.png"
            alt=""
            width="300"
          />
        </p>

        <p>（2）点击主界面右上角的登录/注册按钮。</p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_2.min.jpg"
            alt=""
            width="300"
          />
        </p>

        <p>
          （3）输入卡号密码，并选择“进入智能专业定位系统/进入学科能力系统”直接测评，无需注册和付费。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_3.min.png"
            alt=""
            width="300"
          />
        </p>

        <p>2.使用电脑进行测评</p>
        <p>（1）进入网站：www.career-explore.com</p>
        <p>（2）点击右上角登录/注册。</p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_4.min.png"
            alt=""
            width="500"
          />
        </p>

        <p>（3）在登录窗口输入账号，密码。（不需要注册）</p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_5.min.png"
            alt=""
            width="500"
          />
        </p>

        <p>
          （4）并选择“进入智能专业定位系统/进入学科能力系统”直接测评，无需注册和付费。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_6.min.png"
            alt=""
            width="500"
          />
        </p>

        <h4 style="margin-bottom: 1em;">三、使用一般模式测评步骤</h4>
        <p>1.使用电脑进行测评</p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_7.min.png"
            alt=""
            width="500"
          />
        </p>

        <p>（1）进入网站：www.career-explore.com</p>
        <p>（2）点击右上角登录/注册。</p>
        <p>
          （3）在登录/注册界面点击“快速注册”，进入注册界面（左图）。输入手机号得到验证码进行注册（右图）。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_8.min.png"
            alt=""
            width="500"
          /><img
            src="./../../../../Public/resources/images/templates/home/index/manual_9.min.png"
            alt=""
            width="500"
            style="margin-left: 1em;"
          />
        </p>

        <p>
          （4）选择“进入智能专业定位系统/进入学科能力系统（左图）。进入测评报告目录（右图），选择想要测评的报告，进行测评。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_10.min.png"
            alt=""
            width="500"
          /><img
            src="./../../../../Public/resources/images/templates/home/index/manual_11.min.png"
            alt=""
            width="500"
            style="margin-left: 1em;"
          />
        </p>

        <p>
          （5）如果报告需要付费，将出现以下界面。用微信进行扫描付款后可进行测评。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/manual_12.min.png"
            alt=""
            width="500"
          />
        </p>

        <p style="text-align:center;">
          <a
            class="button button-isi"
            @click="backPage()"
            ><i class="el-icon-back"></i><span>&nbsp;&nbsp;返回</span></a
          >
        </p>
      </div>
    </div>
    <!-- //about-team-->
  </div>
</template>

<script>
export default {
  name: '',
  data: function () {
    return {}
  },
  mounted: function () {},
  methods: {
    backPage(){
      this.$router.back();
    }
  }
}
</script>

<style lang="less">
</style>
